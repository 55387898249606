<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/profile"  style="width: 24px;margin-right: 8px;" src="../../assets/img/back.png">
                    <span style="font-size: 16px;font-weight: 500;color: #484848;">Kayıtlı Kartlarım</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">

    <div style="margin-top: 30px" class="d-flex justify-content-between align-items-center">
        <h2 style="font-size: 16px;font-weight: 500;color: #202020;margin: 0;">Kayıtlı Kartlarım</h2>
    </div>


    <div class="row">

        <div style="margin-top: 15px;" class="col-12">
            <button type="button" class="btn recorded-card">
                <div class="d-flex align-items-center">
                    <img src="../../assets/img/banka.png" width="44" alt="gift icon"> <span style="margin-left:12px">Vakıf Bank Kartım</span>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                    <img src="../../assets/img/trash.svg" width="24" alt="delete">
                    
                </div>
            </button>
        </div>

        <div class="d-flex justify-content-center align-items-center" style="margin-top: 16px; height: 39px;">
            <img src="../../assets/img/moneypay-logo.png" width="55" alt="moneypay logo">
            <span style="font-size: 11px; color: #000;">Powered by Türkpara</span>
        </div>
        <div style="margin-top: 16px;" class="col-12">
            <button type="button" class="btn new-card-button">
                <div class="plus"></div>
                <span>Yeni Kart Ekle</span>
            </button>
        </div>

    </div>
</div>