import { Component, OnInit } from '@angular/core';
import { ActivatedRoute ,Router} from '@angular/router';
import { Barcode, BarcodePicker, ScanResult, ScanSettings, SingleImageModeSettings } from "scandit-sdk";
import { CoreService } from '../services/core.service';
declare var $:any
@Component({
  selector: 'app-scan-delete-product',
  templateUrl: './scan-delete-product.component.html',
  styleUrls: ['./scan-delete-product.component.css']
})
export class ScanDeleteProductComponent implements OnInit {

  public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
  public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;
  public scannerGuiStyle: BarcodePicker.GuiStyle = BarcodePicker.GuiStyle.VIEWFINDER;
  public scannedBarcodes: Barcode[] = [];
  public settings128: ScanSettings;
  public activeSettingsBarcode: ScanSettings;

  public singleImageSettings: SingleImageModeSettings;

  //barcodeInput = 5449000103857;
  barcodeInput;
  cardNumber;
  basketID;
  productDetails;
  barcodeID
  storeID = localStorage.getItem("storeId")

  constructor(public coreService: CoreService, private route: ActivatedRoute,private router:Router) {
    this.settings128 = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.EAN13, Barcode.Symbology.EAN8, Barcode.Symbology.CODE39],
      codeDuplicateFilter: 2000
    });

    this.activeSettingsBarcode = this.settings128

    this.singleImageSettings = {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };
  }

  async ngOnInit() {
    if (localStorage.getItem("basketId")) {
      this.basketID = localStorage.getItem("basketId")
      this.barcodeID = this.route.snapshot.paramMap.get('barcodeID');
    }
    this.cardNumber = localStorage.getItem('virtualOrCardId')
  }

  async scanBarcode(result: ScanResult) {
    //const barcodeID="5449000103857"
    //const barcodeID = "8690520521912"

    console.log(result)

    if (this.scannedBarcodes.length == 0) {

      this.scannedBarcodes = this.scannedBarcodes.concat(result.barcodes);

      console.log(this.scannedBarcodes[0].data)
      this.barcodeInput = parseFloat(this.scannedBarcodes[0].data) 
      this.deleteProduct()
      //location.href = `/mkolay-product?basketID=${this.resultData.basketId}&barcode=${this.scannedBarcodes[0].data}`
    }

  }

  async getProduct() {

    var data = `act=mkolay_productquery&cardnumber=${this.cardNumber}&barcode=${this.barcodeInput}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650&basketId=${this.basketID}`

    console.log(data)

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productDetails = data["resultData"]["productList"][0]
      console.log(this.productDetails)
      this.deleteProductFromBasket()

    })
  }

  async deleteProduct() {

    var data = `act=mkolay_productquery&cardnumber=${this.cardNumber}&barcode=${this.barcodeID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650&basketId=${this.basketID}`

    console.log(data)

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productDetails = data["resultData"]["productList"][0]
      console.log("ürün",this.productDetails)
    })

    if (this.barcodeInput) {
      if(this.barcodeID != this.barcodeInput ){
        this.barcodeInput = null
        this.scannedBarcodes = []
        $("#enterBarcodeModal").modal('hide')
        $("#invalidBarcodeModal").modal('show')
      }
      else{
        this.scannedBarcodes = []
        $("#enterBarcodeModal").modal('hide')
        await this.getProduct()
      }
    
      
    }
  }

  async deleteProductFromBasket() {

    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${this.productDetails['scannedBarcode']}&originalBarcode=${this.productDetails['originalBarcode']}&productCode=${this.productDetails["productCode"]}&quantity=0&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`
    
    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productDetails = data["resultData"]["productList"][0]
      console.log(data)
      this.router.navigateByUrl('/basket')
    })
  
  }

}
