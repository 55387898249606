import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Barcode, BarcodePicker, ScanResult, ScanSettings, SingleImageModeSettings, Scanner } from "scandit-sdk";
import { CoreService } from '../services/core.service';

declare var $;

@Component({
  selector: 'app-scan-add-product',
  templateUrl: './scan-add-product.component.html',
  styleUrls: ['./scan-add-product.component.css']
})
export class ScanAddProductComponent implements OnInit {

  basketID;
  cardNumber

  public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
  public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;
  public scannerGuiStyle: BarcodePicker.GuiStyle = BarcodePicker.GuiStyle.VIEWFINDER;
  public scannedBarcodes: Barcode[] = [];
  public settings128: ScanSettings;
  public activeSettingsBarcode: ScanSettings;
  public cachingDuration: Scanner

  public singleImageSettings: SingleImageModeSettings;

  //barcodeInput = 5449000103857;
  barcodeInput = null;
  storeID = localStorage.getItem("storeId")
  //storeID = 1096

  constructor(public coreService: CoreService,private router:Router) {
    this.basketID = localStorage.getItem('basketId')
    this.cardNumber = localStorage.getItem('virtualOrCardId')

    

    this.settings128 = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.EAN13, Barcode.Symbology.EAN8, Barcode.Symbology.CODE39],
      codeDuplicateFilter: 2000
    });

    this.activeSettingsBarcode = this.settings128

    this.singleImageSettings = {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };

    // var test = this.cachingDuration.getScanSettings()
    // console.log(test)
  }

  ngOnInit(): void {
  }

  scanBarcode(result: ScanResult) {
    //const barcodeID="5449000103857"
    //const barcodeID = "8690520521912"

    console.log(result)

    if (this.scannedBarcodes.length == 0) {

      this.scannedBarcodes = this.scannedBarcodes.concat(result.barcodes);

      console.log(this.scannedBarcodes[0].data)
      //location.href = `/product/${this.scannedBarcodes[0].data}`
      this.checkProduct(this.scannedBarcodes[0].data)
    }

  }

  goProductPage(){
    //location.href = `/product/${this.barcodeInput}`
    this.checkProduct(this.barcodeInput)
  }

  checkProduct(barcode){
    var data = `act=mkolay_productquery&cardnumber=${this.cardNumber}&barcode=${barcode}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650&basketId=${this.basketID}`


    this.coreService.coreServiceFunc(data).subscribe(data => {

      if(data["errorCode"] || data["result"] == 1){
        this.barcodeInput = null
        this.scannedBarcodes = []
        $('#enterBarcodeModal').modal('hide')
        $('#invalidBarcodeModal').modal('show')
      }
      else if(data["errorCode"] == "" || data["result"] == 0){
        const localBarcode = barcode
        this.barcodeInput = null
        this.scannedBarcodes = []
        $('#enterBarcodeModal').modal('hide')
        this.router.navigate(['/product', localBarcode])
      }

      console.log(data)

    })
  }

  openEnterBarcodeModal(){
    $('#invalidBarcodeModal').modal('hide')
    $('#enterBarcodeModal').modal('show')
  }

}
