

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <button (click)="clearCache()">Clear Cache</button>
        </div>
        <div style="word-wrap: break-word;margin-top: 20px;" class="col-md-12">
            <h4>Lat: {{lat}}</h4>
            <h4>Lng: {{lng}}</h4>
            <h4>Virtual or Card ID: {{virtualOrCardId}}</h4>
            <h4>Basket ID: {{basketId}}</h4>
            <h4>Jet QR Code: {{jetQrCode}}</h4>
            <h4>Basket Date: {{basketDate}}</h4>
            <h4>Create Basket Date: {{createBasketDate}}</h4>
            <h4>Store ID: {{storeId}}</h4>
            <h4>Store Name: {{storeName}}</h4>
            <h4>User Info {{userInfo}}</h4>
        </div>

        <div class="col-md-12">
            <!-- <button (click)="testAndroid()">TEST ANDROID</button> -->

            <iframe width="100%" height="300" [src]="testURLLink"></iframe>

        </div>
    </div>
</div>