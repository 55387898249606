import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  productList: any = [];
  cardNumber = localStorage.getItem('virtualOrCardId')
  basketID;
  totalPrice;
  totalQTY;

  storeID;

  isHomepageActive: boolean = false;
  isBasketActive: boolean = false;
  isProfileActive: boolean = false;

  constructor(public coreService: CoreService,private router: Router) {
    this.storeID = localStorage.getItem('storeId')
  }

  ngOnInit(): void {
    this.updateNavigationBarActiveStatus()
    //Product List
    this.basketID = localStorage.getItem('basketId')
    if (this.basketID) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productList = data["resultData"]["productList"]
        for (let index = 0; index < this.productList?.length; index++) {
          this.totalQTY = parseInt(this.productList[index]["quantity"])
          this.productList[index].calcPrice = parseFloat(this.productList[index].calcPrice)
        }
        console.log(data["resultData"])
        this.totalPrice = parseFloat(data["resultData"]["totalAmount"])

      })
    }



    this.coreService.footerData.subscribe(data => {
      console.log(data)
      this.totalPrice = 0
      this.productList = data

      for (let index = 0; index < this.productList?.length; index++) {
        this.totalQTY = parseInt(this.productList[index]["quantity"])
        this.totalPrice = parseFloat(this.totalPrice) + parseFloat(this.productList[index]["calcPrice"])

      }
    })
  }

  updateNavigationBarActiveStatus() {
    if (location.pathname.includes("basket")) {
      this.isBasketActive = true
      this.isHomepageActive = false
      this.isProfileActive = false
    }
    else if (location.pathname.includes("profile")) {
      this.isBasketActive = false
      this.isHomepageActive = false
      this.isProfileActive = true
    }
    else if (location.pathname.includes("shopping-history")) {
      this.isBasketActive = false
      this.isHomepageActive = false
      this.isProfileActive = true
    }
    else if (location.pathname.includes("store")) {
      this.isBasketActive = false
      this.isHomepageActive = true
      this.isProfileActive = false
    }
    else if (location.pathname.includes("scan-add")) {
      this.isBasketActive = false
      this.isHomepageActive = true
      this.isProfileActive = false
    }
    else if (location.pathname.includes("scan-delete")) {
      this.isBasketActive = false
      this.isHomepageActive = true
      this.isProfileActive = false
    }
    else if (location.pathname.includes("product")) {
      this.isBasketActive = false
      this.isHomepageActive = true
      this.isProfileActive = false
    }
    else if (location.pathname.includes("recorded-cards")) {
      this.isBasketActive = false
      this.isHomepageActive = false
      this.isProfileActive = true
    }
    else {
      this.isBasketActive = false
      this.isHomepageActive = false
      this.isProfileActive = false
    }

    console.log(this.isBasketActive, "Basket")
    console.log(this.isHomepageActive, "Homepage")
    console.log(this.isProfileActive, "Profile")
  }

  checkHome() {
    if (this.storeID) {
      this.router.navigateByUrl(`/store/${this.storeID}`);
    }
    else {
      this.router.navigateByUrl(`/`);
    }
  }

}
