<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/store/{{storeID}}" style="width: 24px;margin-right: 8px;" src="../../assets/img/back.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay Kantin</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <img style="width: 156px;" src="../../../../assets/img/payment-fail-image.png">
            <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Üzgünüz, ödemeniz <br> gerçekleşmedi.</h2>

            <h2 class="c-color6" style="font-size: 16px;font-weight: 400;margin-top: 20px;">MoneyPay hesabınızı kontrol ederek <br> tekrar deneyebilirsiniz.</h2>
        </div>

        <div class="col-12 text-center">
            <button routerLink="/payment/credit-card/scan-qr"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Tekrar Dene</button>
        </div>
    </div>
</div>

<app-footer></app-footer>