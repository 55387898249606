<div style="box-shadow: 0px 4px 8px #0000000d;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img (click)="goStore()" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Ürün Detayı</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="box-shadow: 0px 8px 16px #0000000d;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="notify-box-add" [class.active]="notifyState">
                    <div style="width: fit-content;border: 1px solid #00000014;margin-right: 5px;border-radius: 4px;">
                        <img style="width: 36px;margin-left: 10px;margin-right: 10px;"
                            src="{{productDetails?.ImageUrl}}">
                    </div>
                    <div style="margin-right: 5px;">
                        <img *ngIf="notifyStateAdd" style="width: 18px;" src="../../assets/img/check-icon.png">
                        <img *ngIf="notifyStateDelete" style="width: 18px;"
                            src="../../assets/img/notify-delete-icon.png">
                    </div>
                    <div>
                        <h4 *ngIf="notifyStateAdd" class="c-color4" style="margin: 0;font-size: 13px;font-weight: 400;">
                            Ürün sepetinize başarıyla eklendi!</h4>
                        <h4 *ngIf="notifyStateDelete" class="c-color4"
                            style="margin: 0;font-size: 13px;font-weight: 400;">Ürün sepetinizden başarıyla çıkarıldı!
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 20px;" class="col-12 text-center">
                <img style="width: 100px;" src="{{productDetails?.ImageUrl}}">
                <h1 class="c-color4" style="font-size: 16px;font-weight: 500;margin-top: 20px;margin-bottom: 0px;">
                    {{productDetails?.productName}}</h1>
                <span class="c-color6" style="font-size: 14px;font-weight: 400;">{{productDetails?.quantity}}
                    {{productDetails?.unit}}</span>
               
                <div *ngIf="productDetails?.calcPrice != productDetails?.discountedPrice">
                    <h2 class="c-color6" style="font-size: 12px;font-weight: 400;margin: 0;text-decoration: line-through;">
                        {{productDetails?.calcPrice| number : '1.2-2'}} TL
                    </h2>
                    <h2 class="c-color1" style="font-size: 20px;font-weight: 500;margin-top: 10px;margin-bottom: 25px;">
                        {{productDetails?.discountedPrice | number : '1.2-2'}} TL
                    </h2> 
                </div>
                <div *ngIf="productDetails?.calcPrice == productDetails?.discountedPrice">
                    <h2 class="c-color1" style="font-size: 20px;font-weight: 500;margin-top: 10px;margin-bottom: 25px;">
                        {{productDetails?.calcPrice | number : '1.2-2'}} TL
                    </h2> 
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" id="btn-state">
    <div *ngIf="isAddedBasket == 1" class="row">
        <div style="margin-top: 40px;" class="col-12 text-center">
            <div
                style="height:52px;border-radius: 26px;border: 1px solid #EEEEEE;margin: auto;display: inline-flex;align-items: center;">
                <div>
                    <img *ngIf="productDetails?.quantity < 2" (click)="deleteProductToBasket()"
                        style="width: 44px;margin-left: 2px;" src="../../assets/img/delete-icon.png">
                    <img *ngIf="productDetails?.quantity >= 2" (click)="deleteProductToBasket()"
                        style="width: 44px;margin-left: 2px;" src="../../assets/img/mines-icon.png">
                </div>
                <div [ngStyle]="{'margin-right': (!productDetails?.lockQuantity || productDetails?.quantityEdit) ? '10px' : '20px'}" style="display: grid;margin-left: 10px">
                    <span class="c-color1"
                        style="font-size: 20px;font-weight: 500;margin-bottom: 2px;">{{productDetails?.quantity}}</span>
                    <span class="c-color4" style="font-size: 10px;font-weight: 400;">Ürün eklendi</span>
                </div>
                <div *ngIf="productDetails?.lockQuantity else quantityEdit"></div>
                <ng-template #quantityEdit>
                   
                        <div>
                            <img  (click)="addProductToBasket()" style="width: 45px; margin-right: 2px;" src="../../assets/img/plus-icon-product.svg">
                        </div>
                   
                </ng-template>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="isAddedBasket == 0" style="margin-bottom: 15px;margin-top: 174px;" class="col-12 text-center">
            <button (click)="addProductToBasket()"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;" type="button"
                class="btn btn-primary bg-color1  c-color8">Sepete Ekle</button>
        </div>
        <div *ngIf="isAddedBasket" style="margin-bottom: 15px;margin-top: 25px;" class="col-12 text-center">
            <button routerLink="/scan-add-product"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;" type="button"
                class="btn btn-primary bg-color1  c-color8">Alışverişe Devam Et</button>
            <button (click)="goPaymentOptions()"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 20px;border: 1px solid;"
                type="button" class="btn btn-primary bg-color8 c-color1 border-color1">Alışverişi Tamamla</button>
        </div>
    </div>
</div>

<app-footer></app-footer>

<!-- Delete Product Modal -->
<div class="modal fade" id="deleteProductModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/delete-icon-modal.png">

                            <h4 class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 16px;font-weight: 500;margin-bottom: 0px;">
                                Ürünü Sepetinden Silmek <br> İstediğinden Emin misin?</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6 ">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Ürünü Sil</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
