import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasketComponent } from './basket/basket.component';
import { ClearCacheComponent } from './clear-cache/clear-cache.component';
import { LoginQrComponent } from './login-qr/login-qr.component';
import { LoginComponent } from './login/login.component';
import { OtpProcessComponent } from './otp-process/otp-process.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { CreditCardFailComponent } from './payment/credit-card/credit-card-fail/credit-card-fail.component';
import { CreditCardSuccessComponent } from './payment/credit-card/credit-card-success/credit-card-success.component';
import { PayWithCreditCardComponent } from './payment/credit-card/pay-with-credit-card/pay-with-credit-card.component';
import { MoneyPayOtpVerificationComponent } from './payment/money-pay/money-pay-otp-verification/money-pay-otp-verification.component';
import { MoneyPayPaymentOptionsComponent } from './payment/money-pay/money-pay-payment-options/money-pay-payment-options.component';
import { PayWithMoneyPayComponent } from './payment/money-pay/pay-with-money-pay/pay-with-money-pay.component';
import { ProductComponent } from './product/product.component';
import { ProfileComponent } from './profile/profile.component';
import { RecordedCardsComponent } from './recorded-cards/recorded-cards.component';
import { ScanAddProductComponent } from './scan-add-product/scan-add-product.component';
import { ScanDeleteProductComponent } from './scan-delete-product/scan-delete-product.component';
import { SearchStoreQrComponent } from './search-store-qr/search-store-qr.component';
import { ShoppingHistoryDetailComponent } from './shopping-history-detail/shopping-history-detail.component';
import { ShoppingHistoryComponent } from './shopping-history/shopping-history.component';
import { StartShoppingComponent } from './start-shopping/start-shopping.component';
import { StoreComponent } from './store/store.component';
import { WelcomeContinueComponent } from './welcome-continue/welcome-continue.component';
import { WelcomeWizardComponent } from './welcome-wizard/welcome-wizard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { IsLoginGuard } from './guards/is-login.guard';


const routes: Routes = [
  { path: "", component: WelcomeComponent },
  { path: "test", component: ClearCacheComponent },
  { path: "profile", component: ProfileComponent },
  { path: "shopping-history", component: ShoppingHistoryComponent },
  { path: "shopping-history/:shoppingID", component: ShoppingHistoryDetailComponent },
  { path: "product/:barcodeID", component: ProductComponent },
  { path: "basket", component: BasketComponent },
  { path: "login-qr", component: LoginQrComponent },
  { path: "search-with-qr", component: SearchStoreQrComponent },
  { path: "welcome-continue/:storeID", component: WelcomeContinueComponent },
  { path: "scan-add-product", component: ScanAddProductComponent },
  { path: "scan-delete-product", component: ScanDeleteProductComponent },
  { path: "scan-delete-product/:barcodeID", component: ScanDeleteProductComponent },
  { path: "store/:storeID", component: StoreComponent},
  { path: "start-shopping", component: StartShoppingComponent},
  { path: "payment/payment-options", component: PaymentOptionsComponent },
  { path: "payment/credit-card/scan-qr", component: PayWithCreditCardComponent },
  { path: "payment/credit-card/success", component: CreditCardSuccessComponent },
  { path: "payment/credit-card/fail", component: CreditCardFailComponent },
  { path: "payment/money-pay/scan-qr", component: PayWithMoneyPayComponent },
  { path: "payment/money-pay/payment-options", component: MoneyPayPaymentOptionsComponent },
  { path: "payment/money-pay/otp-verification", component: MoneyPayOtpVerificationComponent },
  { path: "recorded-cards", component: RecordedCardsComponent },
  { path: "otp-process", component: OtpProcessComponent,canActivate: [IsLoginGuard]},
  { path: "otp-process/:phone", component: OtpProcessComponent,canActivate: [IsLoginGuard]},
  { path: "login", component: LoginComponent, canActivate: [IsLoginGuard] },
  { path: "welcome-wizard", component: WelcomeWizardComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
