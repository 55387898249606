import { DOCUMENT, Location } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  isLogged = false;
  userInfo: any
  nameInitial: any
  storeID
  constructor(private router: Router, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

    ngOnInit(): void {
      this.storeID = localStorage.getItem("storeId")
      this.userInfo = localStorage.getItem("userInfo")
      this.userInfo = JSON.parse(this.userInfo)
      if (localStorage.getItem("userInfo")) {
        this.getFirstLetters(this.userInfo?.fullName)
      }
  
      if (this.userInfo) {
        this.isLogged = true
      }
    }
  
    logout() {
      localStorage.clear()
      this.router.navigateByUrl("/")
    }
  
    getFirstLetters(str: any) {
      const firstLetters = str?.split(' ').map(word => word.charAt(0)).join('');
      this.nameInitial = firstLetters
    }
  
    back(): void {
      if(this.storeID){
        this.router.navigateByUrl(`/store/${this.storeID}`);

      }
      else{
        this.router.navigateByUrl(`/`);

      }
    }
  
    recordedCards(){
      if(this.userInfo){
      location.href = `https://mkolay.moneypay.com.tr/#/list?userToken=${this.userInfo.paymentToken}`
      }
    }



}
