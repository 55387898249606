<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/back.png" alt="back">
                    <span style="font-size: 16px;font-weight: 500;color: #484848;">MKolay Kantin</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top: 32px;">
    <div class="container-fluid">
        <div class="text-center">
            <div class="icon-lg">
                <img src="assets/img/modal-icon.png" alt="modal-icon">
            </div>
            
            <h1 style="margin-top: 12px;">{{nearStore?.MagazaAdi}} Mağazasına</h1>
            <h2>Hoşgeldin</h2>
            <h3 class="mt-1 px-4">İstediğin ürünü okutup sepete ekleyebilirsin.</h3>
            <button (click)="checkLogin()" type="button" class="btn continue-btn">Devam Et</button>
        </div>
    </div>
</div>