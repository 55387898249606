<div  style="display: flex;flex-direction: column;">
    <div [style.height]="screenHeight">
        <div class="container">
            <div class="row" style="position: absolute; width: 100%;">
                <div style="text-align: left;margin-top: 20px;" class="col-6">
                    <img *ngIf="step > 1" (click)="step = step - 1" style="width: 24px;margin-left: 10px;" src="../../assets/img/back-icon.png">
                    <img *ngIf="step == 1" routerLink="/" style="width: 24px;margin-left: 10px;" src="../../assets/img/back-icon.png">
                </div>
                <div style="text-align: right;margin-top: 20px;" class="col-6" (click)="stepOne()">
                    <span *ngIf="step != 4" class="c-color5" style="font-size: 14px;font-weight: 500;margin-right: 10px;">Atla</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-md-12 px-0 mt-0 wizard-images">
                    <img *ngIf="step == 1" width="100%" [style.height]="screenHeight" [src]="stepOneOptions.path" alt="">
                    <img *ngIf="step == 2" width="100%" [style.height]="screenHeight" [src]="stepTwoOptions.path" alt="">
                    <img *ngIf="step == 3" width="100%" [style.height]="screenHeight" [src]="stepThreeOptions.path" alt="">
                    <img *ngIf="step == 4" width="100%" [style.height]="screenHeight" [src]="stepFourOptions.path" alt="">

                    <!-- <ng-lottie *ngIf="step == 1" width="100%" height="{{screenHeight}}" [options]="stepOneOptions" ></ng-lottie>
                    <ng-lottie *ngIf="step == 2" width="100%" height="{{screenHeight}}" [options]="stepTwoOptions" ></ng-lottie>
                    <ng-lottie *ngIf="step == 3" width="100%" height="{{screenHeight}}" [options]="stepThreeOptions" ></ng-lottie>
                    <ng-lottie *ngIf="step == 4" width="100%" height="{{screenHeight}}" [options]="stepFourOptions" ></ng-lottie> -->
                </div>
            </div>
        </div>
    </div>

    <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: 20px; padding: 0 16px;" *ngIf="step == 1" class="container">
        <div class="row">
            <div  class="col-md-12">
                <div >
                    <h4 class="c-color5" style="margin-top: 20px;margin-bottom:13px;line-height: 30px;font-size: 20px;font-weight: 600;">
                        Giriş yap ve Alışverişe başla!</h4>
    
                    <p class="c-color4 mb-0" style="line-height: 30px;font-size: 16px;font-weight: 400;padding-bottom:30px">
                        Money üyeliğin ile giriş yapıp, alışverişe başlayabilir ve kantin alanına girebilirsin.</p>
                </div>
    
            </div>
        </div>
    
        <div  class="row">
            <div class="col-4">
                <div style="height: 100%;display: inline-flex;align-items: center;">
                    <div class="bg-color1" style="width: 32px;height: 10px;border-radius: 6px;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                </div>
            </div>
            <div style="text-align: right;" class="col-8">
                <button (click)="step = step + 1"
                    style="height: 44px;font-size: 14px;font-weight: 500;border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
            </div>
        </div>
    </div>

    <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: 20px; padding: 0 16px;" *ngIf="step == 2" class="container">
        <!-- <div class="row">
            <div class="col-md-12">
                <div style="padding-left: 15px;padding-right: 15px;min-height: 165px;">
                    <h4 class="c-color5" style="margin-top: 20px;line-height: 24px;font-size: 19px;font-weight: 600;">
                        Ürün ekleyerek sepetini oluştur</h4>
    
                    <p class="c-color4" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 20px;min-height: 72px;">
                        Ürün üzerinde bulunan barkodu okutarak sepetini oluşturabilirsin.</p>
                </div>
    
            </div>
        </div> -->

        <div class="row">
            <div  class="col-md-12">
                <div >
                    <h4 class="c-color5" style="margin-top: 20px;margin-bottom:13px;line-height: 30px;font-size: 20px;font-weight: 600;">
                        Ürün ekleyerek sepetini oluştur</h4>
    
                    <p class="c-color4 mb-0" style="line-height: 30px;font-size: 16px;font-weight: 400;padding-bottom:30px">
                        Ürün üzerinde bulunan barkodu okutarak sepetini oluşturabilirsin.</p>
                </div>
    
            </div>
        </div>
    
    
        <div  class="row">
            <div class="col-4">
                <div style="height: 100%;display: inline-flex;align-items: center;">
                    
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div class="bg-color1" style="width: 32px;height: 10px;border-radius: 6px;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                </div>
            </div>
            <div style="text-align: right;" class="col-8">
                <button (click)="step = step + 1"
                    style="height: 44px;font-size: 14px;font-weight: 500; border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
            </div>
        </div>
        
    </div>
    
    <div style="display: flex;flex-direction: column;justify-content: flex-end;;padding-bottom: 20px; padding: 0 16px;" *ngIf="step == 3" class="container">
        <!-- <div class="row">
            <div style="margin-top: 20px;" class="col-md-12">
                <div style="padding-left: 15px;padding-right: 15px;min-height: 165px;">
                    <h4 class="c-color5" style="margin-top: 20px;line-height: 24px;font-size: 19px;font-weight: 600;">
                        Ödemeni uygulamadan yap</h4>
    
                    <p class="c-color4" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 20px;min-height: 72px;">
                        Kredi kartı veya MoneyPay ile ödemeni tamamlayabilirsin.</p>
                </div>
    
            </div>
        </div> -->

        <div class="row">
            <div  class="col-md-12">
                <div >
                    <h4 class="c-color5" style="margin-top: 20px;margin-bottom:13px;line-height: 30px;font-size: 20px;font-weight: 600;">
                        Ödemeni uygulamadan yap</h4>
    
                    <p class="c-color4 mb-0" style="line-height: 30px;font-size: 16px;font-weight: 400;padding-bottom:30px">
                        Kredi kartı veya MoneyPay ile ödemeni tamamlayabilirsin.</p>
                </div>
    
            </div>
        </div>
    
        <div  class="row">
            <div class="col-4">
                <div style="height: 100%;display: inline-flex;align-items: center;">
                    
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div class="bg-color1" style="width: 32px;height: 10px;border-radius: 6px;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                </div>
            </div>
            <div style="text-align: right;" class="col-8">
                <button (click)="step = step + 1"
                    style="height: 44px;font-size: 14px;font-weight: 500; border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
            </div>
        </div>
    </div>
    
    <div style="display: flex;flex-direction: column;justify-content: flex-end;;padding-bottom: 20px; padding: 0 16px;" *ngIf="step == 4" class="container">
        <!-- <div class="row">
            <div style="margin-top: 20px;" class="col-md-12">
                <div style="padding-left: 15px;padding-right: 15px;min-height: 165px;">
                    <h4 class="c-color5" style="margin-top: 20px;line-height: 24px;font-size: 19px;font-weight: 600;">
                        Turnikeden çık</h4>
    
                    <p class="c-color4" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 20px;min-height: 72px;">
                        Ödemen tamamlandıktan sonra turnikeden çıkabilirsin.</p>
                </div>
    
            </div>
        </div> -->

        <div class="row">
            <div  class="col-md-12">
                <div >
                    <h4 class="c-color5" style="margin-top: 20px;margin-bottom:13px;line-height: 30px;font-size: 20px;font-weight: 600;">
                        Turnikeden çık</h4>
    
                    <p class="c-color4 mb-0" style="line-height: 30px;font-size: 16px;font-weight: 400;padding-bottom:30px">
                        Ödemen tamamlandıktan sonra turnikeden çıkabilirsin.</p>
                </div>
    
            </div>
        </div>
    
        <div  class="row">
            <div class="col-4">
                <div style="height: 100%;display: inline-flex;align-items: center;">
                    
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #EEEEEE;margin-right: 4px;"></div>
                    <div class="bg-color1" style="width: 32px;height: 10px;border-radius: 6px;margin-right: 4px;"></div>
                </div>
            </div>
            <div style="text-align: right;" class="col-8">
                <button (click)="stepOne()"
                    style="height: 44px;font-size: 14px;font-weight: 500; border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8">Hadi Başla</button>
            </div>
        </div>
    </div>


</div>






<!-- Check Store Modal -->
<div class="modal fade" id="checkStoreModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/modal-icon.png">

                            <h4 class="c-color5" style="margin-top: 20px;line-height: 30px;font-size: 19px;">
                                Alışverişinize {{storeName}} mağazasından devam etmek ister misiniz?</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="goCheckLocation()" data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button (click) = "setContinueStore()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Load Basket Modal -->
<div class="modal fade" id="loadBasketModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/load-basket-modal-icon.png">

                            <h4 class="c-color5" style="margin-top: 20px;line-height: 30px;font-size: 19px;">
                                Sepetinizi geri yüklemek ister misiniz?</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="declineLoadBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Hayır</button>
                        </div>
                        <div class="col-6">
                            <button (click)="acceptLoadBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1 c-color8">Evet</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>