import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../services/core.service';
declare var $
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  nearStore: any = [];
  storeID;
  cardNumber = localStorage.getItem('virtualOrCardId')
  basketID;
  productList = []
  userInfo: any

  migrosPinOptions: AnimationOptions = {
    path: '../../assets/img/migros-pin-new.json'
  };

  constructor(public coreService: CoreService, private route: ActivatedRoute, private router: Router, private toastrService: ToastrService) {
    this.storeID = this.route.snapshot.paramMap.get('storeID');
  }

  async ngOnInit() {
    await this.searchNearStoresByLatLong()
    await this.getProductList()
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  }

  async searchNearStoresByLatLong() {
    var data = `act=magazadetay&magazano=${this.storeID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.nearStore = data["ResultObjects"][0]
      console.log(this.nearStore)

    }, (err => {
      console.log(err)
    }))
  }

  async getProductList() {
    //Product List
    this.basketID = localStorage.getItem('basketId')
    if (this.basketID) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productList = data["resultData"]["productList"]
      },err => {
        console.log(err)
      })
    }
  }

  async createBasket() {
    if (!localStorage.getItem("basketId")) {

      this.cardNumber = localStorage.getItem("virtualOrCardId")

      if (this.userInfo) {
        var data = `act=mkolay_createbasket&magazano=${this.storeID}&myhash=1231231233475638457&gsmId=${this.userInfo.gsmId}&cardId=${this.userInfo.cardId}&storeType=K`
      }
      else {
        var data = `act=mkolay_createbasket&magazano=${this.storeID}&myhash=1231231233475638457&storeType=K&virtualcardno=${this.cardNumber}`
      }

      this.coreService.coreServiceFunc(data).subscribe(data => {
        if (data["result"] == 0) {
          console.log(data["resultData"])
          localStorage.setItem('basketId', data["resultData"]["basketId"])
          if (data["resultData"]["basketId"] == null) { localStorage.setItem('basketId', "") }
          localStorage.setItem('jetQrCode', data["resultData"]["jetQrCode"])
          localStorage.setItem('basketDate', new Date().toString())
          localStorage.setItem('createBasketDate', Date.now().toString())
          localStorage.setItem('storeId', this.storeID)
          localStorage.setItem('storeName', data["resultData"]["storeName"])
          this.router.navigateByUrl(`/scan-add-product`);
        }
        else if(data["result"] == 1) {
          this.toastrService.error("Hata", data["userMessage"])
          setTimeout(() => {
            localStorage.removeItem("storeName")
            localStorage.removeItem("basketId")
            localStorage.removeItem("userInfo")
            localStorage.removeItem("virtualOrCardId")
            localStorage.removeItem("basketDate")
            localStorage.removeItem("jetQrCode")
            localStorage.removeItem("createBasketDate")
            location.href = "/login"
          }, 1000);
        }

      })



      // var data = `act=mkolay_createbasket&cardnumber=${this.cardNumber}&magazano=${this.storeID}&myhash=1231231233475638457&storeType=K`


    }
    else if (localStorage.getItem("basketId")) {
      if (localStorage.getItem("basketId") == "null" || localStorage.getItem("basketId") == undefined) {
        this.toastrService.error("Hata", "Sistemsel bir sorun çıktı. Sayfa yenileniyor. Tekrar deneyiniz.")
        
        setTimeout(() => {
          location.reload()
        }, 1000);
      }
      else {
        this.router.navigateByUrl(`/scan-add-product`);
      }

    }

  }

  moneyLoginPopup() {
    this.router.navigateByUrl(`/scan-add-product`);
  }

}
