import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CoreService } from '../services/core.service';

declare var $;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  isAddedBasket;
  barcodeID;
  basketID;
  storeID;
  cardNumber;
  productDetails;
  quantity = 1;
  otherInfoKey

  notifyState = false;
  notifyStateAdd = false;
  notifyStateDelete = false;

  hasValueableProduct = false;

  showJetKasa = 0;
  showMoneyPay = 0;
  showCreditCard = 0;

  screenHeight

  paymentOptionList

  constructor(public coreService: CoreService, private route: ActivatedRoute, private router:Router) {
    this.barcodeID = this.route.snapshot.paramMap.get('barcodeID');
    this.basketID = localStorage.getItem('basketId')
    this.storeID = localStorage.getItem('storeId')
  }

  async ngOnInit() {
    this.cardNumber = localStorage.getItem('virtualOrCardId')

    this.paymentOptionList = JSON.parse(localStorage.getItem('paymentOptionList'))
    console.log(this.paymentOptionList)
    
    await this.getProduct()
    if(!this.paymentOptionList){
     await this.getPaymentOptions()
    }

    
  }

  async getProduct() {

    var data = `act=mkolay_productquery&cardnumber=${this.cardNumber}&barcode=${this.barcodeID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650&basketId=${this.basketID}`

    console.log(data)

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productDetails = data["resultData"]["productList"][0]
      this.productDetails.calcPrice = parseFloat(this.productDetails?.calcPrice) 
      console.log(this.productDetails)
      this.isAddedBasket = this.productDetails?.isInBasket

    })
  }

  // async checkValuableProduct(){

  //   if(this.productDetails?.isValuable && (this.paymentOptionList?.moneyPay || this.paymentOptionList?.creditCard)){

  //     // if(this.productDetails?.otherInfo == "0"){
  //     //   this.otherInfoKey = false
  //     // }
  //     if(this.productDetails?.otherInfo == "1"){
  //       this.otherInfoKey = true
  //       $('#specialProductModal').modal('show')
  //     }
    
  //   }
  //   else if(!this.productDetails?.isValuable){
  //     await this.addProductToBasket()
  //   }
  // }

  async addProductToBasket() {
    if (!this.productDetails.isInBasket) {
      console.log(this.productDetails["productCode"])
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${this.productDetails['scannedBarcode']}&originalBarcode=${this.productDetails['originalBarcode']}&productCode=${this.productDetails["productCode"]}&quantity=${parseFloat(this.productDetails.quantity)}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productDetails = data["resultData"]["productList"].filter(data => data.scannedBarcode == this.barcodeID )[0]
        this.coreService.testRXJS(data["resultData"]["productList"])
        this.notifyStateDelete = false;
        this.notifyState = true
        this.notifyStateAdd = true

        setTimeout(() => {
          this.notifyStateAdd = false;
          this.notifyState = false
        }, 3000);
        this.productDetails.isInBasket = 1
        this.isAddedBasket = 1
        console.log(data)
      })
    }
    else if(this.productDetails.isInBasket){
      console.log(this.productDetails["productCode"])
      
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${this.barcodeID}&originalBarcode=${this.barcodeID}&productCode=${this.productDetails["productCode"]}&quantity=${parseFloat(this.productDetails.quantity) + 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`
      console.log(data)
      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productDetails = data["resultData"]["productList"].filter(data => data.originalBarcode == this.barcodeID )[0]
        console.log(this.productDetails)

        this.coreService.testRXJS(data["resultData"]["productList"])
        this.notifyStateDelete = false;
        this.notifyState = true
        this.notifyStateAdd = true

        setTimeout(() => {
          this.notifyStateAdd = false;
          this.notifyState = false
        }, 3000);

        this.productDetails.isInBasket = 1
        this.isAddedBasket = 1
        console.log(data)

      })
    }

  }

  async deleteProductToBasket() {
    if (parseFloat(this.productDetails.quantity) > 1) {
      console.log(this.quantity)
      console.log(this.productDetails["productCode"])
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${this.productDetails['scannedBarcode']}&originalBarcode=${this.productDetails['originalBarcode']}&productCode=${this.productDetails["productCode"]}&quantity=${parseFloat(this.productDetails.quantity) - 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productDetails = data["resultData"]["productList"].filter(data => data.scannedBarcode == this.barcodeID )[0]
        console.log(this.productDetails)
        this.coreService.testRXJS(data["resultData"]["productList"])
        this.notifyStateAdd = false;
        this.notifyState = true
        this.notifyStateDelete = true

        setTimeout(() => {
          this.notifyState = false
          this.notifyStateDelete = false;
        }, 3000);
        this.productDetails.isInBasket = 1
        this.isAddedBasket = 1
        console.log(data)

      })
    }
    else if(parseFloat(this.productDetails.quantity) == 1 || parseFloat(this.productDetails.quantity) < 1){
      this.router.navigateByUrl(`/scan-delete-product/${this.productDetails?.scannedBarcode}`)
    }

  }

  async getPaymentOptions() {

    var data = `act=mkolaypaymentoptions&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(async data => {
      console.log(data)
      console.log(this.hasValueableProduct)
      var array: any = data;
      var reData = {
        jetKasa: {},
        moneyPay: {},
        creditCard: {}
      }

      for (let index = 0; index < array?.length; index++) {
        if (array[index].type == "JETKASA") {
          reData.jetKasa = array[index]
        }
        if (array[index].type == "MONEYPAY") {
          reData.moneyPay = array[index]
        }
        if (array[index].type == "CREDITCARD") {
          reData.creditCard = array[index]
        }
      }
      console.log(reData)
      if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 1) {
        this.showJetKasa = reData.jetKasa["isEnabled"]

      }
      else if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 0) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
      }

      else if (!this.hasValueableProduct) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
        this.showMoneyPay = reData.moneyPay["isEnabled"]
        this.showCreditCard = reData.creditCard["isEnabled"]
      }

      console.log("JETKASA: " + this.showJetKasa)
      console.log("MONEYPAY: " + this.showMoneyPay)
      console.log("CREDITCARD: " + this.showCreditCard)

      var paymentOptionList = {
        "jetKasa" : this.showJetKasa,
        "moneyPay" : this.showMoneyPay,
        "creditCard":this.showCreditCard
      }

      localStorage.setItem("paymentOptionList" , JSON.stringify(paymentOptionList))
      
      this.paymentOptionList = JSON.parse(localStorage.getItem("paymentOptionList"))

    })
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(){
    this.screenHeight = window.innerHeight
    var el = document.getElementById('btn-state')
    if(this.screenHeight < 715){
      el.style.bottom = "120px"
      el.style.position = "fixed"
    }
    else {
      el.style.position = "inherit"
    }
  }

  goStore(){
    this.router.navigateByUrl(`/store/${this.storeID}`);
  }

  goPaymentOptions(){
    location.href="/payment/payment-options"
  }

}
