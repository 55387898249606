import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from 'src/app/services/core.service';
declare var $

@Component({
  selector: 'app-money-pay-payment-options',
  templateUrl: './money-pay-payment-options.component.html',
  styleUrls: ['./money-pay-payment-options.component.css']
})
export class MoneyPayPaymentOptionsComponent implements OnInit {

  basketId;
  cardNumber = localStorage.getItem('virtualOrCardId');
  productList: any = [];
  productDetails;
  totalPrice;
  totalDiscount;
  selectedPayment = null;
  paymentOptionsList: any = [];
  paymentOptionsDetails;
  transactionId
  agreement

  constructor(public coreService: CoreService , private router:Router, private toastrService: ToastrService) { }

  async ngOnInit() {
    this.basketId = localStorage.getItem('basketId')

    if (this.basketId) {
      await this.getBasket()
      await this.getMoneyPayDetails()
    }
  }

  onNavigatePaymentOptions(){
   
    this.router.navigateByUrl('/payment/payment-options')

}

  async getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productList = data["resultData"]["productList"]
      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
    }, (err => {
      console.log(err)
    }))
  }

  async getMoneyPayDetails() {
    var data = `act=mkolaypaymentinit&cardnumber=${this.cardNumber}&basketId=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {
      console.log(data)
      this.paymentOptionsDetails = data;
      console.log(this.paymentOptionsDetails)
      if (this.paymentOptionsDetails?.statusCode == "-10108") {
        this.toastrService.error(this.paymentOptionsDetails?.message, "Hata")
        setTimeout(() => {
          location.href = "/payment/money-pay/otp-verification"
        }, 2000);
      }
      else if (this.paymentOptionsDetails?.statusCode == "-10107") {
        this.toastrService.error(this.paymentOptionsDetails?.message, "Hata")
        setTimeout(() => {
          location.href = "moneypay://"
        }, 2000);
      }
      else if (this.paymentOptionsDetails?.statusCode == "1") {
        console.log(data["availableOptions"])
        for (let index = 0; index < data["availableOptions"]?.length; index++) {
          this.paymentOptionsList.push(
            {
              ...data["availableOptions"][index],
              paymentStatus: "available",
              isActive: false
            }
          )
        }
        for (let index = 0; index < data["disableOptions"]?.length; index++) {
          this.paymentOptionsList.push(
            {
              ...data["disableOptions"][index],
              paymentStatus: "disable",
              isActive: false
            }
          )
        }
        console.log(this.paymentOptionsList)
      }

    }, (err => {
      console.log(err)
    }))
  }

  selectPayment(payment) {
    console.log(payment)

    if (payment.paymentStatus == "available") {
      for (let index = 0; index < this.paymentOptionsList.length; index++) {
        this.paymentOptionsList[index].isActive = false
      }
      payment.isActive = true
      this.selectedPayment = payment.type
    }
    console.log(this.selectedPayment)
  }

  payWithPaymentOption() {

    if (this.paymentOptionsDetails.statusCode == 1) {
      console.log(this.selectedPayment)
      if (this.selectedPayment == "PAY_WITH_CASH") {
        var data = `act=mkolaypaymentpay&type=${this.selectedPayment}&usertoken=${this.paymentOptionsDetails.userToken}&cardnumber=${this.cardNumber}&basketid=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

        this.coreService.coreServiceFunc(data).subscribe(data => {
          console.log(data)
          if (data["statusCode"] == 1) {
            location.href = "/payment/credit-card/success"
          }
        }, (err => {
          console.log(err)
        }))
      }
      else if (this.selectedPayment == "PAY_WITH_COLENDI") {

        var data = `act=mkolaypaymentpay&basketid=${this.basketId}&type=PAY_WITH_COLENDI_AGREEMENT&usertoken=${this.paymentOptionsDetails.userToken}&cardnumber=${this.cardNumber}&transactionid=&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

        if(this.transactionId){
          var data = `act=mkolaypaymentpay&basketid=${this.basketId}&type=PAY_WITH_COLENDI&usertoken=${this.paymentOptionsDetails.userToken}&cardnumber=${this.cardNumber}&basketid=${this.basketId}&transactionid=${this.transactionId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`
        }
        
        this.coreService.coreServiceFunc(data).subscribe(data => {
          console.log(data)
          if(data["statusCode"] == -10390){
            this.transactionId = data["transactionId"]
            this.agreement = data["agreement"]
            $('#colendiAgreementModal').modal("show")
          }
          if (data["statusCode"] == 1) {
            $('#colendiAgreementModal').modal("hide")
            this.transactionId = ""
            this.agreement = ""
            location.href = "/payment/credit-card/success"
          }
         
        }, (err => {
          console.log(err)
        }))
      }
    }
  }

  addMoney(){
    location.href = "moneypay://"
  }

  closeColendi(){
    this.selectedPayment = null
    this.transactionId = null
    for (let index = 0; index < this.paymentOptionsList.length; index++) {
      this.paymentOptionsList[index].isActive = false
    }
    $('#colendiAgreementModal').modal("hide")
  }

  closeModal(modal){
    $(modal).modal("hide")
  }


}
