<div class="container">
    <div class="row">
        <div style="margin-top: 80px;" class="col-md-12 text-center">
            <img style="width: 200px;" src="../../assets/img/giris-turnike.png">
            <h4 class="c-color5" style="margin-top: 20px;font-weight: 500;font-size: 20px;">Merhaba</h4>
            <p style="font-weight: 400;font-size: 20px;"><span class="c-color1"
                    style="font-weight: 600;font-size: 20px;">MKolay Kantin'e</span> Hoş Geldin!</p>

            <button routerLink="/welcome-wizard"
                style="width: 100%;height: 48px;font-size: 16px;font-weight: 400;background-color: #f8eaef !important; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary c-color1">Kantin Nedir <img style="width: 24px;margin-left: 8px;"
                    src="../../assets/img/play-btn.png"></button>

            <button (click)="stepOne()"
                style="width: 100%;height: 48px;font-size: 14px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
        </div>
    </div>
</div>


<!-- Check Store Modal -->
<div class="modal fade" id="checkStoreModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/modal-icon.png">

                            <h4 class="c-color5" style="margin-top: 20px;line-height: 30px;font-size: 19px;">
                                Alışverişinize {{storeName}} mağazasından devam etmek ister misiniz?</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="goCheckLocation()" data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button (click) = "setContinueStore()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Load Basket Modal -->
<div class="modal fade" id="loadBasketModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/load-basket-modal-icon.png">

                            <h4 class="c-color5" style="margin-top: 20px;line-height: 30px;font-size: 19px;">
                                Sepetinizi geri yüklemek ister misiniz?</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="declineLoadBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Hayır</button>
                        </div>
                        <div class="col-6">
                            <button (click)="acceptLoadBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Evet</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>