import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-otp-process',
  templateUrl: './otp-process.component.html',
  styleUrls: ['./otp-process.component.css']
})
export class OtpProcessComponent implements OnInit {

  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  countdownOtp: any = 10;
  interval: any;
  otpCodeForm: any
  // cardNumber = localStorage.getItem('virtualOrCardId');
  cardNumber = localStorage.getItem("virtualOrCardId")
  basketId;
  phoneNumber: any
  storeID;
  otpCheck
  constructor(private formBuilder: FormBuilder, private router: Router, public coreService: CoreService, private toastrService: ToastrService, private route: ActivatedRoute) {
    this.phoneNumber = this.route.snapshot.paramMap.get('phone');
    this.otpCountDown()


    this.otpCodeForm = this.formBuilder.group({
      code: this.formBuilder.control('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    this.storeID = localStorage.getItem('storeId')
    this.basketId = localStorage.getItem('basketId')
  }

  digitValidate(ele) {
    console.log(ele);
    ele.value = ele.value.replace(/[^0-9]/g, '');
  }

  tabChange(val) {
    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val].focus()
    } else if (ele[val - 1].value == '') {
      ele[val - 2].focus()
    }
  }

  otpCountDown() {
    clearInterval(this.interval)
    let countdown = 60
    this.countdownOtp = countdown
    this.interval = setInterval(() => {
      this.countdownOtp--;
      if (this.countdownOtp <= 0) {
        clearInterval(this.interval)
        return;
      }
    }, 1000)

  }

  reSend() {
    this.codeInput.reset()
    this.sendCode()
    this.otpCountDown()
    var el = document.getElementById('c2');
    el.style.animationName = "test1";
    setTimeout(() => {
      el.style.animationName = "countdown"
    }, 1);

  }

  onCodeChanged(otpCode: string) {
    this.otpCodeForm.patchValue({ code: otpCode })
    this.otpCheck = false
  }

  // this called only if user entered full code
  onCodeCompleted(otpCode: string) {
    this.otpCodeForm.patchValue({ code: otpCode })
    this.otpCheck = true
  }

  sendCode() {

    var data = `act=mkolay_login_start&gsm=${this.phoneNumber}`
    this.coreService.coreServiceFunc(data).subscribe(res => {
      if(res["result"] == 0){
        this.toastrService.success(res["userMessage"],"OTP")
      }
      else if (res["result"] == 1){
        this.toastrService.error(res["userMessage"],"Hata")
      }
    })
    
    // var data = `act=profilkayitwithotp&tel=${this.phoneNumber}&referans=11abc&myhash=11abc&uniqueid=11abc&datetoken=11abc`

    // this.coreService.coreServiceFunc(data).subscribe(res => {
    //   console.log(res)
    //   if (res["statusCode"] == 1) {
    //     this.toastrService.success(res["message"], "OTP")
    //   }

    // }, (err => {
    //   console.log(err)
    // }))

  }

  verifyCode() {
    console.log(this.otpCodeForm.value.code)

    var data = `act=mkolay_login&gsm=${this.phoneNumber}&password=${this.otpCodeForm.value.code}&basketid=`

    console.log(data)

    this.coreService.coreServiceFunc(data).subscribe(res => {
      console.log(res)
      if (res["result"] == 0) {

        localStorage.setItem('userInfo',
          JSON.stringify({
            fullName: res["resultData"].name + ' ' + res["resultData"].surname,
            phoneNumber: res["resultData"].phone,
            gsmId: res["resultData"].gsmId,
            cardId: res["resultData"].cardId,
            paymentToken: res["resultData"].paymenttoken
             
          }));
          localStorage.setItem("virtualOrCardId", res["resultData"].cardId)
         location.href = `/store/${this.storeID}`
      }
      else {
        this.toastrService.error(res["userMessage"], "Hata")
      }

    }, (err => {
      console.log(err)
    }))
  }

  
}
