<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/shopping-history" style="width: 24px;margin-right: 8px;" src="../../assets/img/back.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Alışveriş Geçmişim</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-color2">
    <div class="container-fluid">
        <div class="row">
            <div style="margin-bottom: 12px;margin-top: 12px;" class="col-9">
                <div style="display: inline-flex;align-items: center;">
                    <img style="width: 40px;margin-right: 12px;" src="../../assets/img/migros-pin-icon.svg">
                    <div>
                        <span class="c-color6" style="font-size: 12px;font-weight: 300;">{{shoppingDetail?.transactionHeader?.date | date:"mediumDate"}}</span> <br>
                        <h5 class="c-color5" style="font-size: 14px;font-weight: 500;text-align: left;margin-bottom: 0;">
                            {{shoppingDetail?.transactionHeader?.storeName}}</h5>
                        <span class="c-color1" style="font-size: 14px;font-weight: 500;">{{shoppingDetail?.transactionHeader?.totalAmount}} TL</span>
                    </div>
                </div>
            </div>

            <div style="text-align: right;margin-bottom: 12px;margin-top: 12px;" class="col-3">
                <div style="height: 100%;display: inline-flex;align-items: center;">
                    <a target="_blank" [href]="shoppingDetail?.transactionHeader?.invoiceId">
                    <img style="width: 30px;" src="../../assets/img/pdf-icon.svg">
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>

<div  class="container-fluid" style="margin-top: 20px; max-height: 350px; overflow-x: scroll;">
    <div *ngFor="let item of shoppingDetail?.transactionDetail">
        <div class="row">
            <div class="col-9">
                <div style="display: inline-flex;align-items: center;">
                    <div
                        style="width: 70px;border-radius: 12px;border: 1px solid #EEEEEE;padding: 17px;margin-right: 10px;">
                        <img [src]="item?.imageURL" style="width: 100%;">
                    </div>
                    <div>
                        <span class="c-color5" style="font-size: 14px;font-weight: 500;margin-bottom: 2px;">{{item?.itemName}}</span>
                        <div class="bg-color3" style="border-radius: 4px;padding: 2px 8px;margin-top: 5px;width: fit-content;">
                            <span class="c-color5" style="font-size: 12px;font-weight: 500;margin-bottom: 0px;">Adet: <span class="c-color1" >{{item?.quantity}}</span></span>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;display: inline-grid;align-items: center;" class="col-3 text-end">
                <span class="c-color5" style="font-size: 16px;font-weight: 600;">{{item?.amount}} TL</span>
            </div>
        </div>

        <hr>
    </div>

    <!-- <div>
        <div class="row">
            <div class="col-9">
                <div style="display: inline-flex;align-items: center;">
                    <div
                        style="width: 70px;border-radius: 12px;border: 1px solid #EEEEEE;padding: 17px;margin-right: 10px;">
                        <img src="../../assets/img/nutella.png" style="width: 100%;">
                    </div>
                    <div>
                        <span class="c-color5" style="font-size: 14px;font-weight: 500;margin-bottom: 2px;">Ferfresh Beyaz Sirke 1L</span>
                        <div class="bg-color3" style="border-radius: 4px;padding: 2px 8px;margin-top: 5px;width: fit-content;">
                            <span class="c-color5" style="font-size: 12px;font-weight: 500;margin-bottom: 0px;">Adet: <span class="c-color1" >1</span></span>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;display: inline-grid;align-items: center;" class="col-3 text-end">
                <span class="c-color5" style="font-size: 16px;font-weight: 600;">4,87 TL</span>
            </div>
        </div>

        <hr>
    </div>

    <div>
        <div class="row">
            <div  class="col-9">
                <div style="display: inline-flex;align-items: center;">
                    <div
                        style="width: 70px;border-radius: 12px;border: 1px solid #EEEEEE;padding: 17px;margin-right: 10px;">
                        <img src="../../assets/img/nutella.png" style="width: 100%;">
                    </div>
                    <div>
                        <span class="c-color5" style="font-size: 14px;font-weight: 500;margin-bottom: 2px;">Ferfresh Beyaz Sirke 1L</span>
                        <div class="bg-color3" style="border-radius: 4px;padding: 2px 8px;margin-top: 5px;width: fit-content;">
                            <span class="c-color5" style="font-size: 12px;font-weight: 500;margin-bottom: 0px;">Adet: <span class="c-color1">1</span></span>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;display: inline-grid;align-items: center;" class="col-3 text-end">
                <span class="c-color5" style="font-size: 16px;font-weight: 600;">4,87 TL</span>
            </div>
        </div>

        <hr>
    </div> -->

</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="stepper" style="margin-bottom: 8px;padding: 0 25px;">
                <div class="step step-left active">
                    <div style="display: grid;align-items: center;" class="step-label step-label-left">
                        <img style="width: 34px;" src="../../assets/img/stepper-icon.svg">
                    </div>

                </div>

                <div class="step step-center active">
                    <div style="display: grid;align-items: center;" class="step-label step-label-center">
                        <img style="width: 34px;" src="../../assets/img/stepper-icon-2.svg">
                    </div>

                </div>

                <div class="step step-right active">
                    <div style="display: grid;align-items: center;" class="step-label step-label-right">
                        <img style="width: 34px;" src="../../assets/img/stepper-icon-3.svg">
                    </div>

                </div>

            </div>
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column text-center">
                    <div style="font-size: 14px;font-weight: 400;color: #CFCFCF;margin-bottom:4px"
                        class="step-description">Sepet Tutarı
                    </div>
                    <div style="font-size: 16px;font-weight: 600;" class="step-description c-color5">{{shoppingDetail?.transactionHeader?.basketAmount || 0}}
                    </div>
                </div>
                <div class="d-flex flex-column text-center">
                    <div style="font-size: 14px;font-weight: 400;color: #CFCFCF;margin-bottom:4px"
                        class="step-description">Toplam
                        İndirim</div>
                    <div style="font-size: 16px;font-weight: 600;color: #6CD300;" class="step-description">- {{shoppingDetail?.transactionHeader?.discountAmount || 0}}
                    </div>
                </div>
                <div class="d-flex flex-column text-center">
                    <div style="font-size: 14px;font-weight: 400;color: #CFCFCF;margin-bottom:4px"
                        class="step-description">Toplam Tutar
                    </div>
                    <div style="font-size: 16px;font-weight: 600;" class="step-description c-color5">{{shoppingDetail?.transactionHeader?.totalAmount || 0}}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>