// const firebaseConfig = {
//   apiKey: "AIzaSyAMbyJsrtP1qwZEp9cxjH2eD8Icc6gVR6s",
//   authDomain: "migrosevents.firebaseapp.com",
//   projectId: "migrosevents",
//   storageBucket: "migrosevents.appspot.com",
//   messagingSenderId: "1080236844037",
//   appId: "1:1080236844037:web:1b2462749539ca5a88686c",
//   measurementId: "G-TD7WER61WF"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCLeeMlZV9TAbLkfsFTk3YWOcFTtBusKCM",
  authDomain: "hale-sequence-105509.firebaseapp.com",
  databaseURL: "https://hale-sequence-105509.firebaseio.com",
  projectId: "hale-sequence-105509",
  storageBucket: "hale-sequence-105509.appspot.com",
  messagingSenderId: "919634677960",
  appId: "1:919634677960:web:d2e834717fc7b9256a15fd",
  measurementId: "G-SS4MRLGQCH"
};

//const apiURL = "https://mkolay-api.migros.com.tr"
const apiURL = "https://magaza-iphone.migros.com.tr/wsmkolay/wsgateway.aspx"
const encryptedKey = "2014technofacemigros7190"
const encryptedIV = "migr2019"

const technofaceKey = "AcUi9hzYOXAQCeCwDhYK05QwFCPbLBqmk09/dT8F5TDxJqQ/eCiHLrMuSaJkIstASAa2puA5GSlRYhRsKFIKgZV2YJNUXe3sGEFCYPVX/prufpkU7ycLFONvgLluQ18DVEhfuR8wqBvxTOaJ6w8T/fpXp2a6SM9L0XIA040isqB9BWBDFDkUh4ESwcAaJWkn8TSiqSURvWgx/a+ycj9BMzrbt8whXNkbr9kmk4Ks9MBui2YmEIElWtq5ZifkeWKt5QeI3euMJV5STUJnPpjw5dtL5qov7rHXzoT9K88jdK7JFPJvm7EvtCOyvFCWyXnrdu9+9TjrtleOtM5vXv1On3yGrYaGT2J7tklryZk1FAjEvzNdjWCasVpR69PLuxBWCVlz6vMZFjJrOYudzb3nn1suXOJI/N5aQKAPhCFE9WpQO70ZHz+tkFXQLlhplqxNuqT3Yjz3jSSAq2ewo2EYPKqwbO6TOChrm3vSCemmwj+VBjzPHZspSkgfIbJQRPaJzIoY25bpYkRkRNLjZOKFB5BNe7JvN+ORcqNcgec5fPPm2ROVFVpCYTtGBDoPIJ0mhENfUcE1uFHgHZk4u40g2giYV2vXbIOE6X2eYWiOKYXPM2ynl2tftbnJXv9N/GJOXgWH4pd3WOB35CIYzxfhgDh1pVBEB0E9pNEEzVjo8rLfAQXA/QwM5JfUcSSzmf1a+g27Q/aSFxtOJc2aoerlogvS4YbA0BykwILTUHV12SHenzyodVSybEHQZL58JhLUBWxwygP/Gio7KYOTa8D8RncElCvNKD1nKJak8rSEK+HzA00wmQV6OzeOZeJ4JdgdsjqMvznid856UTTEBY5QxOaBNX4wXp6XJ0ZoQ8iXnn1sEg=="

const migrosKey = "AeHiBtSBQCycOZyNWwMfo+Qm9N3ZRhhqx3qrm204d/KkGvF1QRsODGE6S8EyKkQKp3y+paV/Y70CRpXJtGZNkKAX7EQWQQXvvnNLZDNKuoB3fZ3tZkN1pJNErdLcUYA9QlCisjJ/x530ICEy0it7MPFhq2wne1ReFmeYZutqJ6AxEME4uCUIqJYPFcImLgbZUkI4nP0PUuqXD7YD9j56t6Tl6RykzP+yvV0S0c03oIYHYwhBQS5gczW88Mmf3y0eetDlyUjEvv2daMx9/m70xInr+S5jtzrcPz14i/S+NY3CfjPtYahfsVFcdsyVEDMbaSkQBw/DYQognFBYRlCbNZtTbQKlekcqUh+51mpftIzQAxdL1E10coex7UDacfy+cI+miovPg9TQlHqVpJEVFkO0vsOiX3HT9mIYEaWV5r96jl2oVuTfm50vdZcVJXZbb0nF+95bhbOvpAfSxAwiJBS+WToOcbd8J+dE/O92jR0Jsx1EoK/Og1rIgJMvMaa4xl3Am4m1q6gY3CIU0ya3RxJALAWio4NCtKec5qI+WpPOjS2gaZzkqCYES9D48+wu3Q0B2qNPvXs99dWIK+HoFDepdlnR2QoFHVaIJVCWXpEOlneRStnxZKBh32LpHMCL6352tjJK/ef5rz2/8tOfniVOIstvPtc1ESGw6KJuwkSl1G5/dd2RkR3PbtJuoDzem9/J51PnAbo0yCA4HiPdj04e3+dmxvWJ3p1vZw/BqQVVXRk3o6wyr6Isuygti6wCeTL1PZwUtox8vA0ZlViFHVjJCOPC6JoI9uPr4viAubm3gsU31NfolK1p23g9Ty6zW8LOmMTNDXzU2PUgHBxKt78MhTP2doxobwMASuckDu0="

var scanditKey = technofaceKey

export const environment = {
  production: false,
  firebaseConfig,
  apiURL,
  encryptedKey,
  encryptedIV,
  scanditKey
};
