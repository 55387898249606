import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recorded-cards',
  templateUrl: './recorded-cards.component.html',
  styleUrls: ['./recorded-cards.component.css']
})
export class RecordedCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
