<div style="box-shadow: 0px 4px 8px #0000000d;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/store/{{storeID}}" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Barkod Okut</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 93%;width: 100%;background-color: gray;padding-top: 50px;">
    <div class="container-fluid">
        <div class="col-12 text-center">
            <span style="font-size: 14px;font-weight: 500;color: white; margin-bottom: 30px; display: block;">Şimdi
                ürünün barkodunu okutabilirsin</span>
        </div>
        <div style="text-align: center;" class="col-12 ">
            <div class="scandit-wrapper">
                <div class="cam-border top-left"></div>
                <div class="cam-border top-right"></div>
                <div class="cam-border bottom-left"></div>
                <div class="cam-border bottom-right"></div>
                <div class="animated-scanner-line"></div>
                <scandit-sdk-barcode-picker [enableCameraSwitcher]="false" [enableTorchToggle]="false"
                    (scan)="scanBarcode($event)" [playSoundOnScan]="true" [vibrateOnScan]="true"
                    [singleImageModeSettings]="singleImageSettings" [scanSettings]="activeSettingsBarcode" #picker
                    [videoFit]="videoFit" [guiStyle]="scannerGuiStyle" [style.height]="'40vw'" [style.width]="'100%'">
                </scandit-sdk-barcode-picker>
            </div>

        </div>

        <div style="margin-top: 20px;"  class="col-12 text-center">
            <button data-bs-toggle="modal" data-bs-target="#enterBarcodeModal"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                type="button" class="btn btn-primary bg-color5  c-color8">Barkodu elle gir</button>
        </div>
    </div>
</div>
<app-footer></app-footer>

<!-- Enter Barcode Modal -->
<div class="modal fade" id="enterBarcodeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 10px;margin-bottom: 20px;" class="col-10 text-start">
                            <span class="c-color4" style="font-size: 16px;font-weight: 600;margin-right: 8px;">Barkod
                                Yazınız</span>
                        </div>
                        <div style="margin-top: 10px;margin-bottom: 20px;" class="col-2 text-end">
                            <img data-bs-dismiss="modal" style="width: 24px;" src="../../assets/img/close-icon.png">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h4 class="c-color4" style="font-size: 16px;font-weight: 400;margin-bottom: 0px;">
                                Lütfen barkod numarasını giriniz.</h4>
                        </div>
                        <div style="margin-top: 20px;margin-bottom: 20px;position: relative;" class="col-12">
                            <input [(ngModel)]="barcodeInput" [ngStyle]="{'border': barcodeInput != null ? '2px solid #b93163' : ''}"
                                style="height: 50px;background: white;border: 1px solid #EEEEEE;border-radius: 4px;"
                                placeholder="Barkod Okut" class="form-control" type="text"
                                aria-label="default input example" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                                <img alt="close" *ngIf="barcodeInput != null" (click)="barcodeInput = null" class="remove-input-btn" src="../../assets/img/close-icon.png">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button (click)="goProductPage()"
                            type="button" class="btn continue-btn" [disabled]="barcodeInput == null">Devam Et</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Invalid Barcode Modal -->
<div class="modal fade" id="invalidBarcodeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-end">
                            <img data-bs-dismiss="modal" style="width: 24px;" src="../../assets/img/close-icon.png">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="../../assets/img/invalid-barcode-icon.png" style="width: 82px;">
                            <h4 class="c-color5"
                                style="font-size: 16px;font-weight: 500;margin-bottom: 0px;margin-top: 20px;">
                                Barkod Okunamadı</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-12 text-center">
                            <button data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;border-radius: 8px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Tekrar Barkodu Okut</button>
                        </div>

                        <div style="margin-top: 20px;" class="col-12 text-center">
                            <button (click)="openEnterBarcodeModal()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Barkodu Elle Gir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>