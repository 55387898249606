import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-login-qr',
  templateUrl: './login-qr.component.html',
  styleUrls: ['./login-qr.component.css']
})
export class LoginQrComponent implements OnInit {
  qrData;
  userToken
  basketId;
  storeId:any = "1096";
  currentDate = new Date().toISOString().split('.')[0];
  cardNumber = localStorage.getItem('virtualOrCardId')
  firebaseLoginData;
  productDetails;
  totalPrice
  totalPriceTwo
  totalDiscount
  isAddedBasket
  step = 1
  constructor(public coreService: CoreService, private firestore: AngularFirestore, private router:Router, private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.toastrService.toastrConfig.positionClass = 'toast-bottom-right';
    // this.createBasket()
    this.basketId = localStorage.getItem('basketId')
    this.storeId = localStorage.getItem('storeId')
    // this.qrData = `${this.basketId}_${this.currentDate}_${this.storeId}`
    console.log(this.qrData)
    this.generateQRCode()
  }

  generateQRCode() {
    // act=cpgenerateqrcode&cardnumber=%@&myhash=%@
    // var data = `act=storegenerateqrcode&cardnumber=${this.cardNumber}&basketId=${this.basketId}&qrcode=${this.qrData}&myhash=1231231233475638457&datetoken=29092022120911650`
    var data = `act=cpgenerateqrcode&cardnumber=${this.cardNumber}`
    this.coreService.coreServiceFunc(data).subscribe(data => {
      console.log(data)
      this.qrData = data["resultData"].qrCode
      // this.qrData = "6d77727a-7277-4a70-6e6f-717461636664-75676f61-7962-4c63-6561-6d"
      this.userToken = data["resultData"].userToken
      console.log("qr", this.qrData)
      this.listenFirebase()
      //this.getBasket()
      
    }, (err => {
      console.log(err)
    }))
  }

  listenFirebase() {
    this.firestore.collection('kantin').doc(`${this.qrData}`).valueChanges().subscribe(res => {
      this.firebaseLoginData = res


      // setTimeout(() => {
      //   this.router.navigateByUrl("/welcome-continue")
      // }, 5000);

      if(res["status"] == -1){
        this.toastrService.error("Ödeme bilgisi bulunmamaktadır!")
      }
      else if(res["status"] = 101){
        if(res["storeId"]){
          localStorage.setItem("storeId", res["storeId"])
        }
        if(res["storeName"]){
          localStorage.setItem("storeName", res["storeName"])
        }
       
        this.router.navigateByUrl("/welcome-continue")
      }
      console.log(res)
    })
  }

  addNewCard(){
    location.href = `https://mkolay.moneypay.com.tr/#/list?userToken=${this.userToken}`
   
  }

  shoppingHistory(){
    this.router.navigateByUrl("/shopping-history")
    // var data = `act=mkolayalisverislerim&module=kantin&cardnumber=${this.cardNumber}`
    // this.coreService.coreServiceFunc(data).subscribe(data => {
    //   console.log(data)
      
    // }, (err => {
    //   console.log(err)
    // }))
  }

  // 
}
