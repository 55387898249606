<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img (click)="onNavigatePaymentOptions()" style="width: 24px;margin-right: 8px;" src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MoneyPay ile Öde</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-color11">
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                <div style="display: inline-flex;align-items: center;">
                    <div
                        style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                        <img src="../../assets/img/tax-active-icon.svg" style="width: 30px;">
                    </div>

                    <span  style="font-size: 16px;font-weight: 500;color:#00C851">İndirim Tutarı</span>
                </div>
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                class="col-4 text-end">
                <span  style="font-size: 16px;font-weight: 500;color:#00C851">₺{{totalDiscount | number :
                    '1.2-2'}}</span>
            </div>
        </div>
        <hr style="margin: 0;border-bottom: 2px solid white;">
        <div class="row">
            <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                <div style="display: inline-flex;align-items: center;">
                    <div
                        style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                        <img src="../../assets/img/credit-card-active-icon.svg" style="width: 30px;">
                    </div>

                    <span class="c-color5" style="font-size: 16px;font-weight: 500;">Toplam Tutar</span>
                </div>
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                class="col-4 text-end">
                <span class="c-color5" style="font-size: 16px;font-weight: 500;">₺{{totalPrice |
                    number : '1.2-2'}}</span>
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div *ngFor="let payment of paymentOptionsList" class="row">
        <div style="margin-top: 20px;" class="col-12">
            <div [class.active]="payment.isActive" (click)="selectPayment(payment)" class="payment-box"
                style="width: 100%;border-radius: 8px;padding: 12px;display: inline-block;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 40%;">
                        <div *ngIf="!payment.isActive"
                            style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                        </div>
                        <img *ngIf="payment.isActive" style="width: 20px;margin-right: 8px;"
                            src="../../assets/img/payment-check-icon.png">
                        <img *ngIf="payment.type == 'PAY_WITH_CASH'" style="width: 82px;" src="../../../../assets/img/moneypay-logo.png">
                        <img *ngIf="payment.type == 'PAY_WITH_COLENDI'" style="width: 88px;" src="../../../../assets/img/colendi-logo.png">
                        <span *ngIf="payment.type == 'PAY_WITH_PRO'" style="font-weight: 600;">MoneyPay Pro</span>

                    </div>
                    <div style="width: 60%;text-align: right;">
                        <div *ngIf="payment.paymentStatus == 'available' && payment.type == 'PAY_WITH_CASH'" class="bg-color2" style="border-radius: 8px;padding: 8px 16px;">
                            <h5 class="c-color5"  style="font-size: 12px;margin-bottom: 0;text-align: center;">Cüzdan Bakiyesi: <strong>{{payment.disposableTotal | number : '1.2-2'}} TL</strong></h5>
                        </div>
                        <div *ngIf="payment.paymentStatus == 'disable' && payment.type == 'PAY_WITH_CASH'" class="bg-color2" style="border-radius: 8px;padding: 8px 16px;">
                            <h5 class="c-color5"  style="font-size: 12px;margin-bottom: 0;text-align: center;">Cüzdan Bakiyesi: <strong>{{payment.availableLimit | number : '1.2-2'}} TL</strong></h5>
                        </div>
                        <div *ngIf="payment.paymentStatus == 'available' && payment.type == 'PAY_WITH_PRO'"
                        class="bg-color2" style="border-radius: 8px;padding: 8px 16px;">
                        <h5 class="c-color5" style="font-size: 12px;margin-bottom: 0;text-align: center;">Bono
                            Bakiyesi: <strong>{{payment.disposableTotal | number : '1.2-2'}} TL</strong></h5>
                    </div>
                    <div *ngIf="payment.paymentStatus == 'disable' && payment.type == 'PAY_WITH_PRO'"
                        class="bg-color2" style="border-radius: 8px;padding: 8px 16px;">
                        <h5 class="c-color5" style="font-size: 12px;margin-bottom: 0;text-align: center;">Bono
                            Bakiyesi: <strong>{{payment.availableLimit | number : '1.2-2'}} TL</strong></h5>
                    </div>
                    </div>
                </div>
                <div style="margin-top: 10px;" *ngIf="payment.type == 'PAY_WITH_PRO'">
                    <h5 class="c-color4" style="margin: 0;font-size: 14px;font-weight: 400;">{{payment.title}}</h5>
                </div>
                
                <div style="margin-top: 10px;" *ngIf="payment.type == 'PAY_WITH_COLENDI'">
                    <h5 class="c-color4" style="margin: 0;font-size: 14px;font-weight: 400;">Collendi Hazır Limit ile 30 gün sonra öde</h5>
                </div>

                <div *ngIf="payment.type == 'PAY_WITH_COLENDI' && payment.paymentStatus == 'disable'"  style="margin-top: 10px">
                    <div class="bg-color2" style="border-radius: 8px;padding: 8px 16px;width: 215px;">
                        <h5 class="c-color5" style="font-size: 12px;margin-bottom: 0;text-align: center;">Hazır Limit Bakiyesi: <strong>{{payment?.availableLimit | number : '1.2-2'}} TL</strong></h5>
                    </div>
                </div>

                <div *ngIf="payment.type == 'PAY_WITH_COLENDI' && payment.paymentStatus == 'available'"  style="margin-top: 10px">
                    <div class="bg-color2" style="border-radius: 8px;padding: 8px 16px;width: 215px;">
                        <h5 class="c-color5" style="font-size: 12px;margin-bottom: 0;text-align: center;">Hazır Limit Bakiyesi: <strong>{{payment?.disposableTotal | number : '1.2-2'}} TL</strong></h5>
                    </div>
                </div>

                <div *ngIf="payment.paymentStatus == 'disable'" style="border-top: 1px solid #EEEEEE;margin-top: 10px;">
                    <div style="margin-top: 10px;">
                        <img style="width: 14px;margin-right: 5px;" src="../../../../assets/img/desc-icon-gray.png">
                        <span class="c-color6" style="font-size: 12px;font-weight: 400;">{{payment?.reasonMessage}}</span>
                    </div>

                    <div *ngIf="payment.paymentStatus == 'disable' && payment.type == 'PAY_WITH_CASH'" class="row">
                        <div style="margin-top: 8px;" class="col-12">
                            <button (click)="addMoney()"
                                style="width: 100%;height: 37px;font-size: 12px;font-weight: 500; border-radius: 4px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Para Ekle</button>
                        </div>
                    </div>
                </div>

                



            </div>


        </div>
    </div>

    <div class="row">
        <div style="margin-top: 40px;" class="col-12">
            <button [disabled]="!selectedPayment" (click)="payWithPaymentOption()"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Onayla</button>
        </div>
    </div>
</div>

<div class="modal fade" id="colendiAgreementModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <!-- <img style="width: 82px;" src="../../assets/img/modal-icon.png"> -->

                            <h4 class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 19px;font-weight: 500;">
                                MoneyPay Hazır Limit sözleşmesini onaylamanız gerekmektedir.
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div style="height: 250px; overflow:scroll;" class="col-md-12">
                            <div [innerHTML]="agreement"></div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" style="margin-top: 20px;">
                       
                        <button (click)="closeColendi()"
                        style="margin-right: 15px;width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;"
                        type="button" class="btn btn-primary c-color6 border-color6">Reddet</button>
                        <button (click)="closeModal('#colendiAgreementModal')"
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;"
                        type="button" class="btn btn-primary bg-color1 c-color8">Kabul Et</button>
                    
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>