import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { CoreService } from './services/core.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'mkolay-new-v9';
  currentRoute
  constructor(private location: PlatformLocation, public router: Router, private coreService:CoreService,private _renderer2: Renderer2,@Inject(DOCUMENT) private _document: Document) {
    this.location.onPopState(() => {
      $('.modal').modal('hide')
    });

    if(localStorage.getItem('createBasketDate')){
      var createdDate = Number(localStorage.getItem('createBasketDate'))
      var cacheTime = 5400
      var createdDateTimestampBySeconds = Math.floor(createdDate / 1000)
      var endDateTimestampBySeconds = Math.floor(createdDate / 1000) + cacheTime
      var endDate = endDateTimestampBySeconds * 1000
      var nowDateBySeconds = Math.floor(Number(Date.now()) / 1000)
      console.log(createdDateTimestampBySeconds)
      console.log(endDateTimestampBySeconds)
      console.log(nowDateBySeconds - createdDateTimestampBySeconds)
      if((nowDateBySeconds - createdDateTimestampBySeconds) > cacheTime){
        localStorage.clear()
        window.location.href = "/"
      }
    }

    // if(!localStorage.getItem("virtualOrCardId") && !localStorage.getItem("userInfo")){

    //   var data = `act=mkolay_generatecardnumber`
    //   this.coreService.coreServiceFunc(data).subscribe(data => {
    //     console.log("vir",data)
    //     localStorage.setItem("virtualOrCardId", data["resultData"].cardNumber)
  
    //   })
    // }

    // this.currentRoute = "";
    // this.router.events.subscribe((event: Event) => {
    //     if (event instanceof NavigationStart) {
    //         // Show progress spinner or progress bar
    //         console.log('Route change detected');
    //     }

    //     if (event instanceof NavigationEnd) {
    //         // Hide progress spinner or progress bar
    //         this.currentRoute = event.url;
    //         this.helpCenter();          
    //     }
    // });
  }
  

  ngOnInit(): void {
  }

  // helpCenter(){

  //   let item = document.getElementById("MB_WEBCHAT_WIDGET")
  //   if(this.currentRoute == "/profile"){
  //     if(item){
  //       item.style.display="block"
  //     }
  //     console.log("girdi")
  //     let script = this._renderer2.createElement('script');
  //     script.src = `https://cdn.mindbehind.com/sdk/mindbehind-sdk.js?auto=true&key=63a2aff1b7542c06ea2f2e45&api=tr`;
  //     this._renderer2.appendChild(this._document.body, script);
  //   }
  //   else{
  //     if(item){
  //     item.style.display="none"
  //     }
  //   }
   

  // }
}
