import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { CoreService } from 'src/app/services/core.service';
declare var $: any

@Component({
  selector: 'app-credit-card-success',
  templateUrl: './credit-card-success.component.html',
  styleUrls: ['./credit-card-success.component.css']
})
export class CreditCardSuccessComponent implements OnInit {
  selectedStarValue: any
  storeID = localStorage.getItem("storeId")
  basketId = localStorage.getItem("basketId")
  transactionId = localStorage.getItem("transactionId")
  userInfo
  stars: any = [
    { "id": 1, "value": 1, "url": "assets/img/star-empty.svg" },
    { "id": 2, "value": 2, "url": "assets/img/star-empty.svg" },
    { "id": 3, "value": 3, "url": "assets/img/star-empty.svg" },
    { "id": 4, "value": 4, "url": "assets/img/star-empty.svg" },
    { "id": 5, "value": 5, "url": "assets/img/star-empty.svg" }
  ]

  hash;
  paymentID;
  transactionID;
  firebaseData
  firebaseCollection

  constructor(public coreService: CoreService, private toastrService: ToastrService, private route: ActivatedRoute, private firestore: AngularFirestore) {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))


    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }
        this.hash = params.hash
        this.paymentID = params.paymentid
        this.transactionID = params.transactionid
      });
  }

  ngOnInit(): void {

    this.firestore.collection('payment').doc(`${this.paymentID}`).valueChanges().pipe(take(1)).subscribe(res => {
      console.log(res)
      this.firebaseData = res;
      if (this.firebaseData?.hash == this.hash && this.firebaseData?.paymentId == this.paymentID && this.firebaseData?.transactionId == this.transactionID && this.firebaseData?.status == 5001) {
        console.log("SAMEEEEE!")
        this.firestore.collection('payment').doc(`${this.paymentID}`).update({status: 5999})
        if (this.basketId) {
          setTimeout(() => {
            this.modalOpen('rating')
          }, 1000);
        }
      }
      else {
        location.href = "/"
      }
    })


    

  }


  modalOpen(modal: any) {
    $(`#${modal}`).modal('show')
  }

  modalClose(modal: any) {
    $(`#${modal}`).modal('hide')
  }

  setRating(value: number) {
    this.selectedStarValue = value + 1
    console.log(this.selectedStarValue)
    for (let index = 0; index < this.stars.length; index++) {
      this.stars[index].url = "assets/img/star-empty.svg"
    }

    for (let index = 0; index < value + 1; index++) {
      this.stars[index].url = "assets/img/star.svg"
    }
  }
  completePayment() {

    if (this.basketId) {
      localStorage.removeItem('basketId')
      localStorage.removeItem('basketDate')
      localStorage.removeItem('createBasketDate')
      localStorage.removeItem('step')
      localStorage.removeItem('transactionId')
      localStorage.removeItem('jetQrCode')
      localStorage.removeItem('paymentFlow')
      localStorage.removeItem('isShowedLoginModal')
      this.coreService.testRXJS([])
    }

    // var data = `act=mkolay_sendpaymenttocrm&cardid=${this.userInfo.cardId}&gsmid=${this.userInfo.gsmId}&basketid=${this.basketId}&transactionid=${this.transactionId}&myhash=1231231233475638457`

    // this.coreService.coreServiceFunc(data).subscribe(async data => {
    //   console.log(data)
    //   if (this.basketId) {
    //     localStorage.removeItem('basketId')
    //     localStorage.removeItem('basketDate')
    //     localStorage.removeItem('createBasketDate')
    //     localStorage.removeItem('step')
    //     localStorage.removeItem('transactionId')
    //     localStorage.removeItem('jetQrCode')
    //     localStorage.removeItem('paymentFlow')
    //     localStorage.removeItem('isShowedLoginModal')
    //     this.coreService.testRXJS([])
    //   }

    // }, (err => {
    //   console.log(err)
    //   if (this.basketId) {
    //     localStorage.removeItem('basketId')
    //     localStorage.removeItem('basketDate')
    //     localStorage.removeItem('createBasketDate')
    //     localStorage.removeItem('step')
    //     localStorage.removeItem('transactionId')
    //     localStorage.removeItem('jetQrCode')
    //     localStorage.removeItem('paymentFlow')
    //     localStorage.removeItem('isShowedLoginModal')
    //     this.coreService.testRXJS([])
    //   }
    // }))
  }
  sendScore() {
    var data = `act=mkolay_score&basketid=${this.basketId}&score=${this.selectedStarValue}&gsmid=${this.userInfo.gsmId}&cardid=${this.userInfo.cardId}`
    this.coreService.coreServiceFunc(data).subscribe(async data => {
      this.modalClose('rating')
      this.completePayment()
    })
  }

}
