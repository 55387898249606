import { Component, OnInit } from '@angular/core';
import { CoreService } from '../services/core.service';

declare var $;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  isContinueStore = false;
  isLoadBasket = false;
  storeId;
  storeName;
  basketId = "";
  productList: any = [];
  productCount;

  cardNumber = localStorage.getItem('virtualOrCardId')

  constructor(public coreService: CoreService) { }

  ngOnInit(): void {
    if(localStorage.getItem("storeId") && localStorage.getItem("storeName")){
      this.storeId = localStorage.getItem("storeId")
      this.storeName = localStorage.getItem("storeName")
    }
    if(localStorage.getItem("basketId")){
      this.basketId = localStorage.getItem("basketId")
    }
  }

  async getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.productCount = await this.coreService.coreServiceFunc(data).toPromise().catch(err => {console.log(err)})
    this.productCount = this.productCount?.resultData?.productList?.length

    return this.productCount
  }

  stepOne(){
    if(this.storeId){
      $('#checkStoreModal').modal('show')
    }
    else if(!this.storeId){
      location.href = "/search-with-qr"
    }
  }

  async setContinueStore(){
    this.isContinueStore = true;
    $('#checkStoreModal').modal('hide')

    var test = await this.getBasket()
    console.log(test)

    if(this.basketId && test > 0){
      $('#loadBasketModal').modal('show')
    }
    else if(!this.basketId || (this.basketId && test == 0)){
      location.href = `/store/${this.storeId}`
    }
  }

  acceptLoadBasket(){
    this.isLoadBasket = true;
    location.href = `/store/${this.storeId}`
  }

  declineLoadBasket(){
    this.isLoadBasket = false;
    localStorage.removeItem("basketId")
    location.href = `/store/${this.storeId}`
  }

  goCheckLocation(){
    localStorage.removeItem("storeId")
    localStorage.removeItem("storeName")
    localStorage.removeItem("basketId")
    localStorage.removeItem("paymentOptionList")
    location.href = "/search-with-qr"
  }

}
