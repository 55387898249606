<div style="box-shadow: 0px 4px 8px #0000000d;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/" style="width: 24px;margin-right: 8px;" src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">QR Okut</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-color7" style="width: 100%;height: 100%;position: fixed;">
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 50px;" class="col-12 text-center">
                <span style="font-size: 14px;font-weight: 500;color: white; margin-bottom: 30px; display: block;">MKolay alışverişi yapmak için mağaza girişinde bulunan mağaza QR kodunu okutmalısın</span>
            </div>

            <div style="margin-top: 20px;" class="col-12 text-center">
                <div class="scandit-wrapper">
                    <div class="cam-border top-left"></div>
                    <div class="cam-border top-right"></div>
                    <div class="cam-border bottom-left"></div>
                    <div class="cam-border bottom-right"></div>
                    <div class="animated-scanner-line"></div>
                <scandit-sdk-barcode-picker [enableCameraSwitcher]="false"
                [enableTorchToggle]="false" #picker [playSoundOnScan]="true"
                [vibrateOnScan]="true" [scanSettings]="activeSettingsQR" [singleImageModeSettings]="singleImageSettings" [videoFit]="videoFit" (scan)="onScan($event)" [guiStyle]="scannerGuiStyle"
                    [style.height]="'80vw'" [style.width]="'100%'" [style.display]="'block'">
                </scandit-sdk-barcode-picker>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Enter Barcode Modal -->
<div class="modal fade" id="enterBarcodeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-12">
                            <h4 class="c-color4" style="font-size: 16px;font-weight: 600;margin-bottom: 20px;">
                                QR Kodu Yazınız</h4>
                        </div>
                        <div class="col-12">
                            <h4 class="c-color4" style="font-size: 16px;font-weight: 400;margin-bottom: 0px;">
                                Lütfen mağazada bulunan QR Kodu giriniz.</h4>
                        </div>
                        <div style="margin-top: 20px;margin-bottom: 20px;position: relative;" class="col-12">
                           <input [(ngModel)]="barcodeInput" [ngStyle]="{'border': barcodeInput != null ? '2px solid #B93163' : ''}"
                                style="height: 50px;background: white;border: 1px solid #EEEEEE;border-radius: 4px;"
                                placeholder="Barkod" class="form-control" type="text"
                                aria-label="default input example" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                                <img alt="close" *ngIf="barcodeInput != null" (click)="barcodeInput = null" class="remove-input-btn" src="../../assets/img/close-icon.png">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button (click)="goProductPage()"  type="button" class="btn continue-btn" [disabled]="barcodeInput == null">Devam Et</button>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-12">
                            <h4 class="c-color4" style="font-size: 16px;font-weight: 600;margin-bottom: 20px;">
                                QR Kodu Yazınız</h4>
                        </div>
                        <div class="col-12">
                            <h4 class="c-color4" style="font-size: 16px;font-weight: 400;margin-bottom: 0px;">
                                Lütfen mağazada bulunan QR Kodu giriniz.</h4>
                        </div>
                        <div style="margin-top: 20px;margin-bottom: 20px;" class="col-12">
                            <input [(ngModel)]="barcodeInput" style="height: 50px;background: white;border: 1px solid #EEEEEE;border-radius: 4px;" placeholder="QR Kod" class="form-control" type="number" aria-label="default input example">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button (click)="goProductPage()" style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;border-radius: 8px;"
                            type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>