import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';

declare var $;

@Component({
  selector: 'app-pay-with-money-pay',
  templateUrl: './pay-with-money-pay.component.html',
  styleUrls: ['./pay-with-money-pay.component.css']
})
export class PayWithMoneyPayComponent implements OnInit {

  qrData;
  basketId;
  storeId;
  currentDate = new Date().toISOString().split('.')[0];
  cardNumber = localStorage.getItem('virtualOrCardId')
  firebaseBasketData;
  productDetails;
  totalPrice
  totalPriceTwo
  totalDiscount
  isAddedBasket
  step

  constructor(public coreService: CoreService, private firestore: AngularFirestore,private router:Router) { }

  ngOnInit(): void {
    this.basketId = localStorage.getItem('basketId')
    this.storeId = localStorage.getItem('storeId')
    this.qrData = `${this.basketId}_${this.currentDate}_${this.storeId}`
    console.log(this.qrData)
    this.getBasket()
    // this.listenFirebase()
    if(this.basketId){
      this.step = 2
    }
    else{
      this.step = 1
    }
  }

  // generateQRCode() {
  //   var data = `act=storegenerateqrcode&cardnumber=${this.cardNumber}&basketId=${this.basketId}&qrcode=${this.qrData}&myhash=1231231233475638457&datetoken=29092022120911650`

  //   this.coreService.coreServiceFunc(data).subscribe(data => {
  //     console.log(data)
  //     this.listenFirebase()
  //     //this.getBasket()
      
  //   }, (err => {
  //     console.log(err)
  //   }))
  // }

  // listenFirebase() {
  //   this.firestore.collection('kantin').doc(`${this.basketId}`).valueChanges().subscribe(res => {
  //     this.firebaseBasketData = res
  //     console.log(res)
  //     if(!this.firebaseBasketData){
  //       this.generateQRCode()
  //     }
  //     else if(this.firebaseBasketData){
  //       this.getBasket()
  //     }

  //   })
  // }

  getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      this.goMoneyPayPaymentOptions()
      // if (parseInt(this.productDetails.packageData.quantity) == 0) {
      //   this.isAddedBasket = "0"
      // }
      // else {
      //   this.isAddedBasket = "1"
      // }
      // if(this.firebaseBasketData.status == 1000){
      //   //this.payWithCreditCard(this.totalPrice.toString())
        
      // }
    }, (err => {
      console.log(err)
    }))
  }

  

  async increaseBagToBasket() {

    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=${parseInt(this.productDetails.packageData.quantity) + 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      if (parseInt(this.productDetails.packageData.quantity) == 0) {
        this.isAddedBasket = "0"
      }
      else {
        this.isAddedBasket = "1"
      }
      console.log(data)
    })

  }

  async decreaseBagToBasket() {

    if (parseInt(this.productDetails.packageData.quantity) >= 1) {
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=${parseInt(this.productDetails.packageData.quantity) - 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {


        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
        console.log(this.totalPrice.toString())
        this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
        this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
        if (parseInt(this.productDetails.packageData.quantity) == 0) {
          this.isAddedBasket = "0"
        }
        else {
          this.isAddedBasket = "1"
        }
        console.log(data)
      })
    }

  }

  async setZeroBagToBasket() {


    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=0&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(async data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      if (parseInt(this.productDetails.packageData.quantity) == 0) {
        this.isAddedBasket = "0"
      }
      else {
        this.isAddedBasket = "1"
      }
      $('#addBagModal').modal('hide')
      location.href = "/payment/money-pay/payment-options"
      console.log(data)
    })

  }

  async goMoneyPayPaymentOptions(){
    // $('#addBagModal').modal('hide')
    location.href = "/payment/money-pay/payment-options"
  }

  onNavigatePaymentOptions(){
   
    this.router.navigateByUrl('/payment/payment-options')

}

}
