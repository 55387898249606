<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/back.png" alt="back">
                    <span style="font-size: 16px;font-weight: 500;color: #484848;">MKolay Kantin</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-5">
    <div class="container-fluid">
        <div class="text-center">
            <h1>Hoşgeldin</h1>
            <h3 class="mt-1">Mkolay Kantin’e giriş yapabilmek için <strong>QR kodu</strong> turnikeye okutman gerekiyor.
            </h3>
        </div>

        <div class="qr-code-container mx-auto">
            <div class="qr-corner qr-corner1"></div>
            <div class="qr-corner qr-corner2"></div>
            <div class="qr-corner qr-corner3"></div>
            <div class="qr-corner qr-corner4"></div>
            <div class="qr-code">
                <qrcode [qrdata]="qrData"  [width]="215" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
        <div class="row" style="margin-top: 51px;">
            <div class="col-6 px-2">
                <button type="button" style="border: 1px solid;" (click)="shoppingHistory()" class="btn choice-btn border-color4 bg-color4 ">
                    <div>
                        <img src="assets/img/basket-icon.png" width="40" height="40" alt="basket icon">
                    </div>
                    <div>
                        <span class="c-color4" style="font-size: 14px">Alışveriş Geçmişim </span>
                    </div>
                 
                </button>
            </div>


            <div class="col-6 px-2">
                <button type="button" style="border: 1px solid;" (click)="addNewCard()" class="btn choice-btn border-color1 bg-color9">
                    <div >
                        <img src="assets/img/credit-card.png" width="40" height="40" alt="credit card">
                    </div>
                    <div >
                        <span class="c-color1" style="font-size: 14px;">Yeni Kart Ekle </span>
                    </div>
                </button>
            </div>
        </div>


    </div>
</div>