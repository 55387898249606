<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/back.png" alt="back">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay Kantin</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top: 32px;">
    <div class="container-fluid">
        <div class="text-center">
            <div class="icon-lg">
                <img src="assets/img/open-gate.png" alt="tool gate">
            </div>
            <p class="info-p">Turnikeyi aç butonuna tıklayarak Kantine giriş yapabilirsin! İstediğin ürünü okutup sepete ekleyebilirsin.</p>
            <button type="button" class="btn continue-btn" (click)="createBasket()">Turnikeyi Aç ve Alışverişe Başla </button>
        </div>
    </div>
</div>